.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
}
.headerMothersDayCampaign:global(.__wab_instance) {
  max-width: 100%;
  display: none;
}
.headerMothersDayCampaignglobal__041620251400050720250600_override:global(
    .__wab_instance
  ) {
  display: flex;
}
.blogCard:global(.__wab_instance) {
  max-width: 100%;
}
.blogCardglobal__041620251400050720250600_override:global(.__wab_instance) {
  display: none;
}
