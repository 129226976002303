.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
}
.freeBox {
  display: flex;
  position: relative;
  width: 100%;
  justify-content: center;
  flex-shrink: 0;
  min-width: 0;
  padding: 0px;
}
@media (min-width: 600px) {
  .freeBox {
    overflow: hidden;
  }
}
@media (min-width: 900px) {
  .freeBox {
    height: auto;
  }
}
.freeBoxglobal__041620251400050720250600_override {
  background: #ffedcd;
}
.mainContainer2 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  background: none;
  margin-right: 0px;
  height: 240px;
  min-width: 0;
  border-bottom: 1px solid var(--token-wrFJtVAO5);
}
@media (min-width: 600px) {
  .mainContainer2 {
    justify-content: center;
    align-items: center;
    padding: 0px;
  }
}
@media (min-width: 900px) {
  .mainContainer2 {
    height: 240px;
    background: url("/plasmic/placeit_home_extras/images/backgroundJpg.jpg") top 50% left 50% / cover repeat;
  }
}
@media (min-width: 1024px) {
  .mainContainer2 {
    height: 375px;
    padding: 0px;
  }
}
@media (min-width: 1536px) {
  .mainContainer2 {
    height: 375px;
    max-width: 100%;
  }
}
@media (min-width: 1900px) {
  .mainContainer2 {
    padding-left: 0px;
    max-width: 100%;
    height: 375px;
  }
}
.mainContainer2global__041620251400050720250600_override {
  border-bottom-color: #ffedcd;
  display: none;
}
.headerMothersDayCampaign__ueasH:global(.__wab_instance) {
  max-width: 100%;
  display: none;
}
.headerMothersDayCampaignglobal__041620251400050720250600_override__ueasHixVy:global(
    .__wab_instance
  ) {
  display: none;
}
.texts2 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 50%;
  height: 100%;
  max-width: 100%;
  background: #ededed;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  min-height: 0;
  padding: var(--token-TCakqYrfy) 16px;
}
.texts2 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  min-height: 0;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.texts2 > :global(.__wab_flex-container) > *,
.texts2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.texts2 > :global(.__wab_flex-container) > picture > img,
.texts2
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
@media (min-width: 600px) {
  .texts2 {
    width: 50%;
    height: 100%;
    min-height: 0;
  }
}
@media (min-width: 600px) {
  .texts2 > :global(.__wab_flex-container) {
    align-items: flex-start;
    justify-content: center;
    min-height: 0;
  }
}
@media (min-width: 900px) {
  .texts2 {
    width: 50%;
    background: #ededed;
    height: 100%;
    min-height: 0;
  }
}
@media (min-width: 900px) {
  .texts2 > :global(.__wab_flex-container) {
    min-height: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 8px);
    height: calc(100% + 8px);
  }
}
@media (min-width: 900px) {
  .texts2 > :global(.__wab_flex-container) > *,
  .texts2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .texts2 > :global(.__wab_flex-container) > picture > img,
  .texts2
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 8px;
  }
}
@media (min-width: 1024px) {
  .texts2 {
    background: #ededed;
    width: 50%;
  }
}
@media (min-width: 1280px) {
  .texts2 {
    width: 55%;
  }
}
@media (min-width: 1536px) {
  .texts2 {
    width: 55%;
    padding: 0px 0px 0px var(--token-cTiaoKBgu);
  }
}
@media (min-width: 1536px) {
  .texts2 > :global(.__wab_flex-container) {
    align-items: flex-start;
    justify-content: center;
  }
}
@media (min-width: 1900px) {
  .texts2 {
    width: 100%;
    min-width: 0;
  }
}
@media (min-width: 1900px) {
  .texts2 > :global(.__wab_flex-container) {
    min-width: 0;
  }
}
.texts2global_ctaLevel_monthly {
  background: #ededed;
}
.texts2global_ctaLevel_annual {
  background: #ededed;
}
.texts2global_ctaLevel_annual > :global(.__wab_flex-container) {
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
  margin-top: calc(0px - 6px);
  height: calc(100% + 6px);
}
.texts2global_ctaLevel_annual > :global(.__wab_flex-container) > *,
.texts2global_ctaLevel_annual
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.texts2global_ctaLevel_annual > :global(.__wab_flex-container) > picture > img,
.texts2global_ctaLevel_annual
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
  margin-top: 6px;
}
.texts2global__041620251400050720250600_override {
  display: none;
}
.text {
  position: relative;
  width: 92px;
  height: auto;
  max-width: 100%;
  font-size: 14px;
  text-align: center;
  color: #535353;
  font-family: "Source Sans Pro", sans-serif;
  border-radius: 20px;
  border: 1px solid #000000;
}
@media (min-width: 600px) {
  .text {
    width: 91px;
  }
}
@media (min-width: 900px) {
  .text {
    font-size: 16px;
    width: 99px;
    margin-right: 0px;
    padding-right: 0px;
  }
}
.textglobal__041620251400050720250600_override {
  display: none;
}
.h1 {
  color: #191919;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: var(--token-wmteqkiSA);
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  letter-spacing: 0px;
  min-width: 0;
}
@media (min-width: 600px) {
  .h1 {
    font-size: 24px;
    text-align: left;
  }
}
@media (min-width: 900px) {
  .h1 {
    color: #191919;
    font-size: 24px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
}
@media (min-width: 1024px) {
  .h1 {
    font-family: "Poppins", sans-serif;
    font-size: 32px;
    font-weight: 700;
    line-height: var(--token-wmteqkiSA);
    color: #191919;
  }
}
@media (min-width: 1280px) {
  .h1 {
    max-width: 640px;
    font-size: 40px;
  }
}
@media (min-width: 1536px) {
  .h1 {
    font-size: 40px;
  }
}
.h1global_ctaLevel_annual {
  color: #191919;
}
.h1global_ctaLevel_pastdue {
  color: #191919;
}
.link__vMntS {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  font-size: 14px;
  color: var(--token-yV5G9sNB3cwe);
  font-weight: 600;
  text-align: left;
  letter-spacing: normal;
  user-select: none;
  padding-left: 0px;
  line-height: 17.6px;
  font-family: "Source Sans Pro", sans-serif;
  min-width: 0;
}
@media (min-width: 600px) {
  .link__vMntS {
    line-height: var(--token-wmteqkiSA);
    text-align: left;
    font-size: 16px;
  }
}
@media (min-width: 900px) {
  .link__vMntS {
    color: #191919;
    padding-top: 0px;
    display: block;
  }
}
@media (min-width: 1024px) {
  .link__vMntS {
    padding: 0px 0px 4px;
  }
}
@media (min-width: 1280px) {
  .link__vMntS {
    padding-bottom: 4px;
    font-size: 18px;
  }
}
@media (min-width: 1536px) {
  .link__vMntS {
    padding-bottom: 4px;
    font-size: 24px;
  }
}
.linkglobal_ctaLevel_visitor__vMntSneSEs {
  font-size: 14px;
}
.linkglobal__041620251400050720250600_override__vMntSixVy {
  display: none;
}
@media (min-width: 900px) {
  .link__lgeZg {
    color: var(--token-TDUv9IlsPLe);
    font-weight: 600;
    font-size: 18px;
  }
}
@media (min-width: 1536px) {
  .link__lgeZg {
    font-size: 18px;
  }
}
.imgs2 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  width: 50%;
  height: 100%;
  max-width: 100%;
  background: url("/plasmic/placeit/images/backgroundJpg4.jpg") top 50% left 50% / cover repeat;
  min-height: 0;
  padding: 0px;
}
@media (min-width: 600px) {
  .imgs2 {
    width: 50%;
    align-items: flex-end;
    justify-content: center;
  }
}
@media (min-width: 900px) {
  .imgs2 {
    width: 50%;
    align-items: center;
    justify-content: flex-end;
    display: flex;
    flex-direction: row;
  }
}
@media (min-width: 1024px) {
  .imgs2 {
    width: 50%;
  }
}
@media (min-width: 1280px) {
  .imgs2 {
    width: 45%;
  }
}
@media (min-width: 1536px) {
  .imgs2 {
    width: 45%;
    justify-content: center;
    align-items: flex-start;
  }
}
@media (min-width: 1900px) {
  .imgs2 {
    width: 100%;
    min-width: 0;
  }
}
.imgs2global__041620251400050720250600_override {
  display: none;
}
.img {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 100%;
  align-self: auto;
  object-position: center center;
  height: 100%;
  min-width: 0;
  min-height: 0;
}
.img > picture > img {
  object-fit: cover;
  object-position: center center;
}
@media (min-width: 600px) {
  .img {
    object-position: right center;
    object-fit: cover;
  }
}
@media (min-width: 600px) {
  .img > picture > img {
    object-fit: cover;
    object-position: right center;
  }
}
@media (min-width: 900px) {
  .img {
    align-self: auto;
    object-fit: cover;
    object-position: center center;
  }
}
@media (min-width: 900px) {
  .img > picture > img {
    object-fit: cover;
    object-position: center center;
  }
}
@media (min-width: 1024px) {
  .img {
    object-fit: cover;
  }
}
@media (min-width: 1024px) {
  .img > picture > img {
    object-fit: cover;
  }
}
@media (min-width: 1280px) {
  .img {
    object-position: right top;
  }
}
@media (min-width: 1280px) {
  .img > picture > img {
    object-position: right top;
  }
}
@media (min-width: 1536px) {
  .img {
    object-position: right center;
  }
}
@media (min-width: 1536px) {
  .img > picture > img {
    object-position: right center;
  }
}
@media (min-width: 1900px) {
  .img {
    object-fit: cover;
    object-position: center center;
  }
}
@media (min-width: 1900px) {
  .img > picture > img {
    object-fit: cover;
    object-position: center center;
  }
}
.headerMothersDayCampaign___4AN9E:global(.__wab_instance) {
  height: 100%;
  min-height: 0;
  display: none;
}
.headerMothersDayCampaignglobal__041620251400050720250600_override___4AN9EIxVy:global(
    .__wab_instance
  ) {
  display: flex;
}
