.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  padding-top: var(--token-TCakqYrfy);
  min-width: 0;
}
.heroSlider:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.campaignContent:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.headerHpBookday:global(.__wab_instance) {
  max-width: 100%;
}
.base:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.hpSlide1Scheduler:global(.__wab_instance) {
  max-width: 100%;
}
.blog:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.vdayHomepageHeader:global(.__wab_instance) {
  max-width: 100%;
  display: none;
}
.blogCard:global(.__wab_instance) {
  max-width: 100%;
  display: none;
}
.hpBlogScheduler:global(.__wab_instance) {
  max-width: 100%;
}
