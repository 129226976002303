.mainContainer {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  background: #ffedcd;
  width: 100%;
  max-width: none;
  height: auto;
  flex-shrink: 0;
  min-height: 240px;
  min-width: 0;
  padding: 0px;
  margin: 0px;
}
@media (min-width: 600px) {
  .mainContainer {
    min-width: 100%;
    height: auto;
    min-height: 240px;
  }
}
@media (min-width: 900px) {
  .mainContainer {
    min-height: 240px;
  }
}
@media (min-width: 1024px) {
  .mainContainer {
    min-height: 375px;
  }
}
@media (min-width: 1280px) {
  .mainContainer {
    min-height: 375px;
  }
}
@media (min-width: 1536px) {
  .mainContainer {
    min-height: 375px;
  }
}
@media (min-width: 1900px) {
  .mainContainer {
    justify-content: center;
    align-items: center;
    min-height: 375px;
    min-width: 100%;
  }
}
.text {
  display: flex;
  flex-direction: column;
  width: 260px;
  height: auto;
  max-width: 100%;
  background: none;
  flex-shrink: 0;
  padding: 16px;
}
.text > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.text > :global(.__wab_flex-container) > *,
.text > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.text > :global(.__wab_flex-container) > picture > img,
.text > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 8px;
}
@media (min-width: 600px) {
  .text {
    width: 260px;
    flex-shrink: 0;
    padding: 16px;
  }
}
@media (min-width: 600px) {
  .text > :global(.__wab_flex-container) {
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 8px);
    height: calc(100% + 8px);
  }
}
@media (min-width: 600px) {
  .text > :global(.__wab_flex-container) > *,
  .text > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .text > :global(.__wab_flex-container) > picture > img,
  .text
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 8px;
  }
}
@media (min-width: 900px) {
  .text {
    width: 522.4px;
    flex-shrink: 0;
    padding: 16px;
  }
}
@media (min-width: 900px) {
  .text > :global(.__wab_flex-container) {
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 16px);
    height: calc(100% + 16px);
  }
}
@media (min-width: 900px) {
  .text > :global(.__wab_flex-container) > *,
  .text > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .text > :global(.__wab_flex-container) > picture > img,
  .text
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 16px;
  }
}
@media (min-width: 1024px) {
  .text {
    width: 434px;
    padding-right: 24px;
    padding-left: 24px;
    flex-shrink: 0;
  }
}
@media (min-width: 1280px) {
  .text {
    width: 686.67px;
    flex-shrink: 0;
    padding: 16px 24px;
  }
}
@media (min-width: 1536px) {
  .text {
    width: 768px;
    flex-shrink: 0;
    padding: 16px 24px;
  }
}
@media (min-width: 1900px) {
  .text {
    width: 768px;
    flex-shrink: 0;
  }
}
@media (min-width: 1900px) {
  .text > :global(.__wab_flex-container) {
    align-items: flex-start;
    justify-content: center;
  }
}
.h1 {
  width: 100%;
  height: auto;
  max-width: 100%;
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  letter-spacing: 0px;
  line-height: 120%;
  font-weight: 700;
  color: #191919;
  min-width: 0;
}
@media (min-width: 600px) {
  .h1 {
    letter-spacing: 0px;
    line-height: 120%;
    font-size: 24px;
  }
}
@media (min-width: 900px) {
  .h1 {
    font-size: 28px;
    line-height: 120%;
    letter-spacing: 0px;
  }
}
@media (min-width: 1024px) {
  .h1 {
    font-size: 36px;
    letter-spacing: 0px;
    line-height: 120%;
  }
}
@media (min-width: 1280px) {
  .h1 {
    padding-left: 0px;
    font-size: 36px;
    line-height: 120%;
    letter-spacing: 0px;
  }
}
@media (min-width: 1536px) {
  .h1 {
    font-size: 40px;
    line-height: 120%;
    letter-spacing: 0px;
  }
}
@media (min-width: 1900px) {
  .h1 {
    font-size: 40px;
    line-height: 120%;
    letter-spacing: 0px;
  }
}
.link {
  color: #191919;
  text-decoration-line: underline;
}
.text__ag01B {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: #191919;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  line-height: 17.6px;
  letter-spacing: normal;
  padding-top: 0px;
  min-width: 0;
}
@media (min-width: 600px) {
  .text__ag01B {
    letter-spacing: 0px;
    line-height: 110%;
    font-size: 14px;
  }
}
@media (min-width: 900px) {
  .text__ag01B {
    font-size: 14px;
    line-height: 110%;
    letter-spacing: 0px;
  }
}
@media (min-width: 1024px) {
  .text__ag01B {
    font-size: 20px;
    line-height: 110%;
    letter-spacing: 0px;
  }
}
@media (min-width: 1280px) {
  .text__ag01B {
    font-size: 20px;
    line-height: 110%;
    letter-spacing: 0px;
  }
}
@media (min-width: 1536px) {
  .text__ag01B {
    font-size: 24px;
    line-height: 110%;
    letter-spacing: 0px;
  }
}
@media (min-width: 1900px) {
  .text__ag01B {
    font-size: 24px;
    line-height: 110%;
    letter-spacing: 0px;
  }
}
.buttonMegaUltaCta:global(.__wab_instance):global(.__wab_instance) {
  max-width: 100%;
}
.image {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 377.6px;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: flex-end;
  flex-shrink: 0;
  padding: 0px 8px 0px 0px;
}
@media (min-width: 600px) {
  .image {
    width: 377.6px;
    flex-shrink: 0;
  }
}
@media (min-width: 900px) {
  .image {
    width: 377.6px;
    flex-shrink: 0;
  }
}
@media (min-width: 1024px) {
  .image {
    width: 590px;
    flex-shrink: 0;
  }
}
@media (min-width: 1280px) {
  .image {
    width: 593.33px;
    flex-shrink: 0;
  }
}
@media (min-width: 1536px) {
  .image {
    width: 768px;
    flex-shrink: 0;
  }
}
@media (min-width: 1900px) {
  .image {
    width: 768px;
    flex-shrink: 0;
  }
}
.img {
  object-fit: cover;
  height: 100%;
  max-width: 100%;
  width: 100%;
  max-height: 240px;
  min-width: 0;
  min-height: 0;
}
.img > picture > img {
  object-fit: cover;
}
@media (min-width: 600px) {
  .img {
    width: 100%;
    height: 100%;
    max-height: 240px;
    min-width: 0;
    min-height: 0;
  }
}
@media (min-width: 900px) {
  .img {
    max-height: 240px;
  }
}
@media (min-width: 1024px) {
  .img {
    max-height: 375px;
    max-width: 100%;
  }
}
@media (min-width: 1536px) {
  .img {
    max-height: 375px;
    width: 100%;
    height: 100%;
    min-width: 0;
    min-height: 0;
  }
}
@media (min-width: 1900px) {
  .img {
    max-height: 375px;
  }
}
